/** @jsx jsx */
import "../scss/ckeditor-styles.scss";

import {css, jsx} from "@emotion/core";
import SEO from "../components/seo";
import LayoutErwachsene from "../components/erwachsene-layout";
import Sprechblase from "../components/sprechblase";
import React from "react";

// @Todo: Here, the speech-bubble is hackily styled via selectors. Move this to the component
const pageStyle = css`
    font-size: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    &>div {
        
        @media screen and (max-width: 800px) {
            width: 90%;
        }
        svg+div>div {
            width: unset;
        }
    }
    article {
        width: 50%;
        @media screen and (max-width: 1024px) {
            width: 100%;
            padding: 0 1rem;
            box-sizing: border-box;
        }
        img {
            max-width: 100%;
        }
    }
`;

// Todo: Replace the fixed img height by a dynamic value.
const illustrationStyle = css`
    min-height: 0px;
    height: 100%;
    width: 80%;
    margin: 0 auto;
    flex: 1;
    div {
        height: 100%;
        width: 100%;
        margin: 0 auto;
    }
    picture {
        display: unset;
        height: 100%;
    }
    img {
        height: auto;
        width: 60%;
        margin: 0 auto;
    }
    @media screen and (max-width: 1024px) {
        img {
            height: auto;
        }
    }
`;

const textStyle = css`
    padding: 1rem 2rem;
    z-index: 1;
    width: 50%;
    font-size: 1.5rem;
`;

const StartErwachsene = ({pageContext}) => {

    return <LayoutErwachsene longPage={true}>
        <SEO title="Ben und Stella wissen Bescheid! | Erwachsenenbereich" />
        <div css={pageStyle} >
            <Sprechblase stroke={"white"} strokeWidth={0} fill={"white"}
                         top={true}>
                <div css={textStyle} dangerouslySetInnerHTML={{__html: pageContext.begruessung}} />
            </Sprechblase>
            <div css={illustrationStyle}>
                <div dangerouslySetInnerHTML={{ __html: pageContext.image }}></div>
            </div>
        </div>
    </LayoutErwachsene>
};

export default StartErwachsene;